.splitCard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    min-height: 600px; /* Adjust as needed for a “hero” feel */
  }
  
  /* On smaller screens, stack them vertically. */
  @media screen and (max-width: 768px) {
    .splitCard {
      grid-template-columns: 1fr;
    }
  }
  
  .slideshow {
    position: relative;
    overflow: hidden;
    /* If you want the slideshow to be exactly half, 
       then the parent is 1fr / 1fr columns, so it’s 50% by default 
       but we can add a max-height or ratio if desired:
    */
  }
  
  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.6s ease; /* fade effect */
    background-size: cover;
    background-position: center;
  }
  
  /* Active slide is fully visible */
  .activeSlide {
    opacity: 1;
  }
  
  /* Right side text */
  .textContent {
    padding: 40px; /* spacing example */
    display: flex;
    flex-direction: column;
    justify-content: center; /* center vertically */
    background-color: #f9f9f9; /* or white or none */
  }
  
  .title {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 1.4;
  }
  
  .cta {
    display: inline-block;
    padding: 12px 20px;
    background-color: var(--color-blue, #3399ff);
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }
  
  .cta:hover {
    background-color: #0077ee;
  }
  
  /* Mobile adjustments. 
     Since we’re stacking at < 768px:
  */
  @media screen and (max-width: 768px) {
    .slideshow {
      min-height: 300px;
    }
  
    .textContent {
      padding: 20px;
      align-items: center; /* center text horizontally if desired */
    }
    
    .title {
      font-size: 24px;
      text-align: center;
    }
    
    .description {
      text-align: center;
      max-width: 600px;
    }
  }
  