.heroSlideshowContainer {
    /* Large container wrapping both sides */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns: left for slideshow, right for text */
    width: 100%;
    min-height: 600px; /* Adjust if you want a shorter/taller hero */
    position: relative; /* So overlapping text is possible if needed */
    height: 100vh;
  }

  .slideWrapper {
    /* Takes up the entire .slideshowSide container */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* For a fading slideshow, you might toggle opacity here: */
    opacity: 0;
    transition: opacity 1s ease-in-out; /* fade in/out */
    /* If you want active slides above others */
    z-index: 1;
  }
  
  /* Slideshow side (left) */
  .slideshowSide {
    position: relative;
    background: #000; /* Optional: if images have transparency or you need fallback */
    overflow: hidden;
  }
  
  /* Each image in the slideshow stack */
  .slideImage {
    /* Fill the wrapper */
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .gradientOverlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 15%; /* how wide the gradient extends from the right */
    height: 100%;
    pointer-events: none; /* so clicks pass through, optional */
  
    background: linear-gradient(
      to left, 
      rgb(0, 0, 0, 1) /* black */,
      rgba(0, 0, 0, 0)   /* transparent */
    );
  }
  
  
  /* We'll apply .activeSlide via JS to the currently displayed image */
  .activeSlide {
    opacity: 1;
    z-index: 2; /* Make sure the active slide is above others */
  }
  
  /* Right side text area */
  .textSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;      /* Some breathing room */
    color: #000;        /* Text color */
    background: none;   /* You said no background, so keep it transparent or none */
    text-align: center;
  }
  
  /* Headline styling, adjust to taste */
  .title {
    color: #fff;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Paragraph or subtext styling */
  .subtitle {
    font-size: 1.1rem;
    line-height: 1.4;
    max-width: 400px;
  }

  .verticalText {
    gap: 20px;
    display: flex;
  }

  .verticalNames {
    display: flex;
    flex-direction: column; /* Stack letters vertically */
    align-items: center;
    justify-content: flex-start;
    font-size: 4rem; /* Larger font size */
    font-family: Helvetica, Arial, sans-serif; /* Use Helvetica font */
    color: #cbcb00; /* Set text color to yellow */
    line-height: 1;
}

  .verticalNames span {
      display: block;
      margin-bottom: -20px; /* Adjust spacing manually */
      transform: translateY(2px); /* Fine-tune alignment */
  }

  .verticalLogo img {
    padding: 10px;
  }

  .verticalLogo img {
    height: 120px;
  }
  
  /* ----------- Mobile / small screen overlap logic ----------- */
  @media screen and (max-width: 600px) {
    .heroSlideshowContainer {
      grid-template-columns: 1fr;  /* Single column on small screens */
      grid-template-rows: auto;    /* Let content define height */
    }

    .gradientOverlay {
      width: 0%;
    }
  
    .slideshowSide {
      /* Make the slideshow fill the container, so text can overlap on top */
      position: relative;
      min-height: 400px; /* or any height that suits mobile */
    }
  
    .textSide {
      /* Position the text on top of the slideshow background */
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      z-index: 3;
      color: #fff;  /* Might want white if the slideshow is dark behind it */
      /* If you do want a slight overlay, you could do:
         background-color: rgba(0,0,0,0.3);
         but you said "with no background," so skip that. */
    }
  }
  

  @media screen and (min-width: 600px) {

    .heroSlideshowContainer{
      grid-column: span 2;
    }
  }
  
  @media screen and (min-width: 960px) {
  
    .heroSlideshowContainer{
      grid-column: span 2;
    }
  }
    
  
  @media screen and (min-width: 1200px) {
  
    .heroSlideshowContainer{
      grid-column: span 2;
    }
  }
  
  @media screen and (min-width: 1400px) {

    .heroSlideshowContainer{
        grid-column: span 2;
      }
  }
  
  @media screen and (min-width: 1600px) {
    
    .heroSlideshowContainer{
      grid-column: span 2;
    }
  }