.aboutSection {
  padding: 40px 20px 40px;
  color: var(--color-black);
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  flex-flow: column-reverse;
  text-align: center;
}

@media screen and (min-width: 960px) {
  .aboutSection {
    padding: 160px 0 160px;
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .aboutSection {
    padding: 360px 0 120px;
    flex-flow: row;
  }
}

.title {
  margin-top: 20px;
  font-size: 24px;
  line-height: 160%;
  font-weight: 500;
}

.link {
  display: block;
  font-size: 20px;
  margin-top: 40px;
  font-weight: 500;
  transition: color 200ms ease;
}

@media screen and (min-width: 960px) {
  .title {
    margin-top: 0;
    font-size: 36px;
    max-width: 680px;
  }

  .link {
    margin-top: 60px;
  }
}

.link:hover {
  color: var(--color-blue);
  text-decoration: none;
}

.text {
  align-self: flex-start;
  justify-self: center;
  margin-top: auto;
  margin-bottom: auto;
}

.videos {
  align-self: center;
  margin: 0 auto;
  flex-flow: nowrap;
  display: none;
}

@media screen and (min-width: 1200px) {
  .videos {
    display: flex;
    align-self: flex-end;
    margin: 0 0 0 auto;
  }
}

.videoColumn {
  display: flex;
  flex-flow: column;
  margin-left: 20px;
}

.videoColumnFirst {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0;
}

@media screen and (min-width: 960px) {
  .videoColumnFirst {
    margin-left: 20px;
  }
}

.videoColumn :nth-child(1) {
  margin-bottom: 20px;
}

.video {
  border-radius: 0px;
}