/* This is a nested grid with two columns */
.aboutGrid {
  padding: 0 0 60px;
  grid-column-start: 1;
  grid-column-end: 13;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media screen and (min-width: 960px) {
  .aboutGrid {
    padding: 0 0 240px;
    grid-template-columns: repeat(4, 1fr);
  }
}

.video {
  border-radius: 0px;
}

.smallVideo {
  border-radius: 0px;
  margin-bottom: 20px;
}

.first {
  margin-top: 20px;
}

.second {
  margin-bottom: 0;
}

@media screen and (min-width: 960px) {
  .first {
    margin-top: 30px;
  }

  .second {
    margin-bottom: 20px;
  }
  
  .third {
    margin-top: 60px;
  }
  
  .fourth {
    margin-top: 90px;
  }
}