
.cardPerspectiveWrapper {
  transform-style: preserve-3d;
  transform: perspective(921px);
}

.contentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  perspective: 800px;
  backface-visibility: hidden;
}

.card {
  position: relative;
  width: auto;
  height: auto;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 800px;
  backface-visibility: hidden;
  background: var(--color-pink);
  /* height: 1200px; */
  border-radius: 0px;
  /* transition: all 300ms ease-out; */
  display: flex;
  align-content: center;
  justify-content: center;
  /* cursor: none; */
}

.card:hover .image {
  /*transform: scale(1);*/
}

.featuredContent {
  display: flex;
  align-content: center;
  justify-content: center;
}

.featuredContent img {
  /*max-width: 80%;*/
  margin: auto;
}

/* .figure {
  margin: auto;
} */

.bottom {
  margin: auto 0 0;
}

@media screen and (min-width: 600px) {
  .card {
    height: 1200px;
  }

  /* Featured cards take up an entire row */
  .featured {
    grid-column: span 2;
  }

}

@media screen and (min-width: 960px) {
  .card {
    height: 1200px;
  }

  /* Featured cards take up an entire row */
  .featured {
    grid-column: span 2;
  }

  .featuredContent img {
    /*max-width: 60%;*/
  }
}

@media screen and (min-width: 1200px) {
  .card {
    height: 1200px;
  }

  /* Featured cards take up an entire row */
  .featured {
    grid-column: span 2;
  }

}

@media screen and (min-width: 1400px) {
  .card {
    height: 1200px;
  }

  .featuredContent img {
    /*max-width: 76%;*/
  }
}

@media screen and (min-width: 1600px) {
  .card {
    height: 1200px;
  }
}

.pink {
  background: var(--color-pink);
}

.pink:hover {
  background: hsla(0, 100%, 82%, 1);
}

.blue {
  background: var(--color-blue);
}

.blue:hover {
 background: hsla(203, 75%, 43%, 1);
}

.green {
  background: var(--color-green);
}

.green:hover {
  background: hsla(162, 70%, 50%, 1);
 }

.purple {
  background: var(--color-purple);
}

.purple:hover {
  background: hsla(271, 73%, 64%, 1);
}

.yellow {
  background: var(--color-yellow);
}

.yellow:hover {
  background: hsla(48, 100%, 64%, 1);
}

.teal {
  background: var(--color-teal);
}

.teal:hover {
  background: hsla(180, 47%, 28%, 1);
}

.image {
  width: auto;
  height: auto;
  /*transform: translateZ(0) scale(1.015);*/
  transition: transform 200ms ease;
}

.title {
  position: absolute;
  bottom: 70px;
  left: 30px;
  font-weight: 1000;
  font-size: 25px;
  /*line-height: 24px;*/
  padding: 12px 20px;
  border-radius: 0px;
  /*background: var(--color-white);*/
  color: var(--color-white);
}

.projectType {
  position: absolute;
  bottom: 40px;
  left: 30px;
  font-weight: 800;
  font-size: 18px;
  /*line-height: 24px;*/
  padding: 12px 20px;
  border-radius: 0px;
  /*background: var(--color-white);*/
  color: var(--color-white);
}

.projectYear {
  position: absolute;
  bottom: 30px;
  left: 30px;
  font-weight: 800;
  font-size: 18px;
  /*line-height: 24px;*/
  padding: 12px 20px;
  border-radius: 0px;
  /* background: var(--color-white); */
  color: var(--color-black);
}

.projectSize {
  position: absolute;
  bottom: 15px;
  left: 30px;
  font-weight: 800;
  font-size: 18px;
  /*line-height: 24px;*/
  padding: 12px 20px;
  border-radius: 0px;
  /*background: var(--color-white);*/
  color: var(--color-white);
}