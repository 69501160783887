
.pink {
  background: var(--color-pink);
}

.blue {
  background: var(--color-blue);
}

.yellow {
  background: var(--color-yellow);
}

.purple {
  background: var(--color-purple);
}

.green {
  background: var(--color-green);
}

.teal {
  background: var(--color-teal);
}

.project-hero-wrapper {
  position: relative;
  height: 340px;
  overflow: hidden;
}

.project-hero-background {
  top: 0;
  left: 0;
  content: '';
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.project-hero {
  display: flex;
  flex-flow: column;
  align-content: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: calc(100% - 44px);
}

.project-hero--bottom {
  height: 100%;
  align-content: flex-end;
}

.project-hero-image {
  position: relative;
  z-index: 5;
  margin: auto;
  pointer-events: none;
}

.align-image-bottom {
  margin: 60px auto 0;
  padding: 0 20px;
}

.project-intro {
  padding-top: 20px;
  padding-bottom: 40px;
  grid-column-start: 1;
  grid-column-end: 13;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 8px;
}

.project-main {
  grid-column: 1;
  grid-column-end: 13;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 160px;
}

.image-2up {
  grid-column-start: 1;
  grid-column-end: 13;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.project-image--full {
  padding: 0px 0px;
  grid-column-start: 1;
  grid-column-end: 13;
}

.project-image--half {
  padding: 0px 0px;
}

.project-image {
  background: var(--color-grey);
  border-radius: 0px;
  display: flex;
  flex-flow: column;
  align-content: center;
  justify-content: center;
}

.project-video {
  background: var(--color-grey);
  border-radius: 0px;
}

.project-image img {
  margin: None;
}

.body {
  grid-column-start: 1;
  grid-column-end: 13;
  padding: 16px 0;
}

em {
  font-weight: bold;
}

.body .paragraph {
  font-size: 16px;
}

.body a {
  text-decoration: underline;
}

.project-title {
  font-weight: 600;
  font-size: 24px;
  grid-column-start: 1;
  grid-column-end: 12;
}

.project-description {
  grid-column-start: 1;
  grid-column-end: 12;
}

.iframe-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.iframe-container iframe,
.iframe-container emebd,
.iframe-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

figcaption {
  padding: 20px 0;
  font-size: 16px;
  text-align: center; 
}

figcaption a {
  text-decoration: underline;
}

video {
  border-radius: 0px;
}

.case-list {
  font-size: 16px;
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 40px;
}

.case-list li {
  margin-bottom: 20px;
}

.case-list a {
  text-decoration: underline;
}

.project-about {
  font-weight: 500;
}

.details-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.first-detail {
  padding-top: 20px;
  grid-column-start: 1;
  grid-column-end: 12;
}

.second-detail {
  grid-column-start: 1;
  grid-column-end: 12;
}

@media screen and (min-width: 960px) {

  .project-main {
    margin-bottom: 320px;
  }

  .project-hero {
    height: 100%;
  }
  
  .body {
    padding: 160px  0;
    grid-column-start: 4;
    grid-column-end: 10;
  }

  .body .paragraph {
    font-size: 20px;
  }

  .project-intro {
    padding-bottom: 140px;
    grid-row-gap: 20px;
  }

  .project-hero {
    margin-top: -68px;
    /* magic number to match the navigation placeholder height */
    /* better way to do this is just have a new nav on mobile render */
  }

  .project-hero--bottom {
    margin-top: 0;
  }

  .project-hero-wrapper {
    height: 760px;
  }

  .project-hero-image {
    /* margin: -80px auto 0; */
    margin: auto;
  }

  .align-image-bottom {
    margin: 20px auto 0;
    padding: 0 40px;
  }

  .project-intro {
    padding-top: 120px;
  }

  .project-image--full {
    padding: 0px 0px;
  }
  
  .project-image--half {
    padding: 0px 0px;
  }

  .first-detail {
    padding-top: 0;
    grid-column-start: 2;
    grid-column-end: 5;
  }
  
  .second-detail {
    grid-column-start: 6;
    grid-column-end: 12;
  }

  .image-2up {
    grid-template-columns: repeat(2, 1fr);
  }

  .project-title {
    font-size: 42px;
    grid-column-start: 2;
    grid-column-end: 5;
  }
  
  .project-description {
    grid-column-start: 6;
    grid-column-end: 12;
  }

  .project-lede {
    margin-bottom: 20px;
  }

  .project-image-8 {
    grid-column-start: 3;
    grid-column-end: 11;
  }

  .case-list {
    font-size: 20px;
  }

  .details-wrapper {
    grid-column-start: 1;
    grid-column-end: 12;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .align-image-bottom {
    padding: 0;
  }

  .first-detail {
    grid-column-start: 6;
    grid-column-end: 8;
  }
  
  .second-detail {
    grid-column-start: 8;
    grid-column-end: 12;
  }
}

@media screen and (min-width: 1600px) {
  .project-hero-wrapper {
    height: 920px;
  }
}

.detail {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-content: center;
  overflow: hidden;
}

.icon-wrapper {
  padding: 8px;
  border-radius: 100px;
  background: var(--color-pink);
  margin-right: 16px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-label {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-black);
}

@media screen and (min-width: 960px) {
  .details-label {
    font-size: 24px;
  }
}