/* https://github.com/sharanda/manrope */

/* @font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope-extrabold.woff2") format("woff2"),
  url("../fonts/manrope-extrabold.woff") format("woff");
  font-style: normal;
  font-weight: 800; 
} */

/* @font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope-bold.woff2") format("woff2"),
  url("../fonts/manrope-bold.woff") format("woff");
  font-style: normal;
  font-weight: 700; 
} */

/* @font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope-semibold.woff2") format("woff2"),
  url("../fonts/manrope-semibold.woff") format("woff");
  font-style: normal;
  font-weight: 600; 
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope-medium.woff2") format("woff2"),
  url("../fonts/manrope-medium.woff") format("woff");
  font-style: normal;
  font-weight: 500; 
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope-regular.woff2") format("woff2"),
  url("../fonts/manrope-regular.woff") format("woff");
  font-style: normal;
  font-weight: 400; 
} */

@font-face {
  font-family: "EB Garamond Regular";
  src: url("https://db.onlinewebfonts.com/t/6926332095f9ae1ca2ffa09796c6002e.eot");
  src: url("https://db.onlinewebfonts.com/t/6926332095f9ae1ca2ffa09796c6002e.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/6926332095f9ae1ca2ffa09796c6002e.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/6926332095f9ae1ca2ffa09796c6002e.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/6926332095f9ae1ca2ffa09796c6002e.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/6926332095f9ae1ca2ffa09796c6002e.svg#EB Garamond Regular")format("svg");
}

/* @font-face {
  font-family: "Manrope";
  src: url("../fonts/manrope-light.woff2") format("woff2"),
  url("../fonts/manrope-light.woff") format("woff");
  font-style: normal;
  font-weight: 300; 
} */