/* @import "./reset.css"; */
@import "./typography.css";

/* CSS Variables */
:root {
  --color-black: #000000;
  --color-white: #fff;
  --color-grey: #f9f9f9;
  --color-pink: hsla(0, 100%, 87%, 1);
  --color-pink-dark: hsla(340.54,62.71%,76.86%);
  --color-yellow: hsla(48, 100%, 70%, 1);
  --color-green: hsla(162.16,33.64%,56.86%);
  --color-blue: hsla(203, 75%, 48%, 1);
  --color-purple: hsla(273.82,61.8%,82.55%);
  --color-teal: hsla(180, 47%, 31%, 1);
}

figure {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: "Manrope", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  font-family: "EB Garamond Regular";
  line-height: 1.6;
  font-weight: 500;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  background-color: var(--color-black);
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* grid-column-gap: 20px;
  grid-row-gap: 20px; */
  /* max-width: calc(100% - 40px); */
  margin: auto;
}

@media screen and (min-width: 960px) {
  .grid {
    max-width: 100%;
  }
}


@media screen and (min-width: 2000px) {
  .grid {
    max-width: 100%;
  }
}

.grid--full {
  max-width: calc(100% - 40px);
}

@media screen and (min-width: 960px) {
  .grid--full {
    max-width: calc(100% - 120px);
  }
}

.work-grid {
  grid-column-start: 1;
  grid-column-end: 13;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 0px;
  /* grid-row-gap: 20px; */
  transform: scale(1);
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0;
}

@media screen and (min-width: 960px) {
  .work-grid {
    grid-template-columns: repeat(2, 1fr);
    /* grid-column-gap: 20px; */
  }

  .container {
    padding: 0 60px;
  }
}

a {
  color: var(--color-black);
  text-decoration: none;
  transition: color 150ms ease;
}

a:hover {
  text-decoration: none;
  color: var(--color-blue);
}

img {
  max-width: 100%;
  display: block;
}

.h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
}

.h2 {
  margin-bottom: 22px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}

.h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}

.h4 {
  font-size: 14px;
  font-weight: 500;
}

.h5 {
  font-size: 12px;
}

.h6 {
  font-size: 10px;
  font-weight: 500;
}

.h7 {
  font-size: 12px;
  font-weight: 500;
}

.grid-title {
  grid-column: 1 / 13;
  margin-bottom: 40px;
}

.paragraph--large {
  font-size: 16px;
  line-height: 1.6;
}

.paragraph {
  margin-bottom: 20px;
  font-size: 14x;
  font-weight: 500;
}

@media screen and (min-width: 960px) {
  .h1 {
    font-size: 48px;
  }
  
  .h2 {
    /* font-size: 42px; */
    font-size: 36px;
    /* margin-bottom: 40px; */
    margin-bottom: 24px;
  }

  .h3 {
    font-size: 32px;
  }

  .h4 {
    font-size: 24px;
  }

  .h5 {
    font-size: 20px;
  }
  
  .h6 {
    font-size: 16px;
  }
  
  .paragraph--large {
    font-size: 32px;
  }

  .paragraph {
    font-size: 24px;
    margin-bottom: 40px;
  }
}

/* About */
.about {
  padding-bottom: 60px;
  max-width: calc(100% - 40px);
}

.bio {
  grid-column: 1 / 13;
}

.bio-body {
  grid-column: 1 / 13;
}

.experience {
  grid-column: 1 / 13;
}

.previous-work {
  grid-column: 1 / 13;
}

@media screen and (min-width: 960px) {
  .about {
    padding-bottom: 280px;
  }

  .bio {
    grid-column: 2 / 9;
  }
  
  .bio-body {
    grid-column: 2 / 8;
  }
  
  .experience {
    grid-column: 2 / 6;
  }
  
  .previous-work {
    grid-column: 7 / 11;
  }

  .next-project {
    margin-bottom: 40px;
  }
}

.list {
  margin-top: 40px;
  list-style: none;
}

.list-item {
  margin-bottom: 40px;
}

.timeline {
  margin-bottom: 0;
}


/* Cursor */
.circle {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: rgba(30, 145, 214, 1); 
  border-radius: 200px;
  pointer-events: none;
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.cursor-text {
  flex: auto;
  font-size: inherit;
  pointer-events: none;
  margin: auto;
}