.footer {
  padding: 20px 0 0;
  grid-column-start: 1;
  grid-column-end: 13;
}

@media screen and (min-width: 1200px) {
  .footer {
    padding: 180px 0 0;
  }
}

.card {
  background: var(--color-grey);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  height: 240px;
  border-radius: 0px;
}

.title {
  margin: 0 auto;
  font-size: 24px;
  line-height: 160%;
  font-weight: 500;
}

.contact a {
  color: var(--color-blue);
}

.contact a:hover {
  text-decoration: underline;
}

.contact {
  color: var(--color-blue);
}

.icon {
  position: relative;
  top: -6px;
  height: 32px;
  width: 32px;
  display: inline-block;
}

.footerLinks {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-flow: column-reverse;
  font-weight: 500;
}

.openSource {
  align-self: center;
  margin: 30px auto 0;
  font-size: 14px;
}

.socialLinks {
  align-self: center;
  margin: 0 auto;
  padding-right: 30px;
}

.socialLink {
  padding-left: 30px;
  font-size: 14px;
  transition: color 150ms ease;
}

.socialLink:hover {
  color: var(--color-blue);
  text-decoration: none;
}


@media screen and (min-width: 960px) {
  .footerLinks {
    flex-flow: row;
  }

  .card {
    height: 600px;
  }

  .title {
    font-size: 36px;
  }

  .socialLink {
    font-size: 18px;
  }

  .socialLinks {
    margin: 0 0 0 auto;
    align-self: flex-end;
    padding-right: 0;
    padding-left: 60px;
  }

  .openSource {
    align-self: flex-start;
    margin: 0 auto 0 0;
    font-size: 18px;
  }
}
